/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import './fonts.scss';
@import './variables';
@import './mixins.scss';
@import './map.scss';
@import './no-ui-slider.scss';

@import "codemirror/lib/codemirror";
@import "codemirror/theme/material";
@import "codemirror/addon/hint/show-hint";

@import 'gridster.scss';
@import 'tinymce.scss';

* {
    box-sizing: border-box;
    @supports (-moz-appearance:none) {
        scrollbar-color: $WHITE $GRAY_MD;
        scrollbar-width: thin;
    }
}
html {
    height: 100%;
    scrollbar-color: var(--scrollbar-color);
    scrollbar-width: thin;
    --mdc-typography-font-family: Gilmer, sans-serif; // Fixes angular/components#26329, can be removed in Angular 16 or 17.
}

body.console {
    min-width: 768px;
    min-height: 750px;
}

body {
    position: relative;
    height: 100%;
    margin: 0;
    color: var(--color);
    background-color: var(--bkg-color);
}
@media only screen and (max-width: 768px) {
    body {
        min-width: auto;
    }
}
body, input, textarea, button, select {
    font-family: var(--font-family);
    font-weight: var(--font-weight);
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
        list-style-type: none;
    }
}
p {
    margin: 0;
    font-size: 14px;
}
textarea, select, input, button, li, div {
    outline: none;
}
button {
    cursor: pointer;
    border: none;
    background: none;
    color: $WHITE;
}
button, li, a, div {
    box-shadow: none;
}
.page-wrapper {
    height: 100%;
    padding: $page-wrapper-padding;
    .page-header {
        padding: 5px 0 0 5px;
    }
    .page-content {
        padding: 5px;
        margin-top: 15px;
    }
}

a {
    color: var(--hyperlink-color);
    text-decoration: none;
    cursor: pointer;
}


/* Global styles*/
h1, .h1 {
    font-size: 44px;
    font-weight: $font-weight-400;
}

h2, .h2 {
    font-size: 36px;
    font-weight: $font-weight-400;
}

h3, .h3 {
    font-size: 28px;
    font-weight: $font-weight-400;
}

h4, .h4 {
    font-size: 22px;
    font-weight: $font-weight-400;
    margin: 0;
}

h5, .h5 {
    font-size: 18px;
    font-weight: $font-weight-400;
}

.bold {
    font-weight: $font-weight-400!important;
}

.text-link {
    font-weight: $font-weight-300;
    color: $BLUE;
    background: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.sub-text {
    @include sub-text
}

.btn {
    display: inline-block;
    outline: none;
    align-items: flex-start;
    text-align: center;
    margin: 0;
}

button, .btn {
    position: relative;
    font-size: 18px;
    border-radius: $border-radius;
    transition: background-color .15s ease;
    padding: 20px 30px;
    border: none;
    &.mat-calendar-body-cell {
        padding: 0;
        font-size: $font-size-14;
    }
    &.config-form-button {
        font-size: $font-size-12;
        padding: 12px 36px;
    }
    &.small {   
        // match button height with text input height
        padding: 10px 30px; 
        font-size: $font-size-12;
    }
    &.standard-width {
        min-width: 135px;
        padding-left: 0;
        padding-right: 0;
    }
    &.circle {
        border-radius: 50%;
    }
    &.primary {
        @include primaryButton;
    }
    &.white {
        @include white-button;
    }
    &.gray-dk {
        @include gray-dk-button;
    }
    &.gray {
        @include gray-button;
    }
    &.gray-lt {
        @include gray-lt-button;
    }
    &.gray-xlt {
        @include gray-xlt-button;
    }
    &.transparent-gray-dk {
        @include gray-dk-button;
    }
    &.microsoft-red {
        @include microsoft-red-button;
    }
    &.blue-lt {
        @include blue-lt-button;
    }
    &.red {
        @include red-button;
    }
    &.green-bright {
        @include green-bright-button;
    }
    &.red-bright {
        @include red-bright-button;
    }
    &.cancel {
        background-color: $GRAY;
        color: $WHITE;
        &:hover:not([disabled]):not(.disabled) {
            background-color: $GRAY;
        }
    }
    &.disabled, &:disabled {
        opacity: .5;
        cursor: default;
    }
    &.circle-icon {
        width: 40px;
        height: 40px;
        padding: 0;
        flex-shrink: 0;
        font-size: $font-size-16;
        border-radius: $border-circle;
        &.image-selection input {
            position: absolute;
            opacity: 0;
            width: 40px;
            height: 40px;
            cursor: pointer;
        }
        &.large {
            width: 55px;
            height: 55px;
            font-size: $font-size-20;
        }
        &.x-large {
            width: 75px;
            height: 75px;
            font-size: $font-size-32;
        }
        &.small {
            width: 20px;
            height: 20px;
            font-size: $font-size-12;
        }
    }
}

input {
    @include text-input;
}

input[type=text] {
    @include text-input;
}

input[type=checkbox] {
    @include text-input;
    width: auto;
}

textarea{
    @include text-input;
    max-height: unset;
}

.file-input {
    padding: 5px 10px;
}

.text-link {
    @include text-link;
}

.text-link-white {
    @include text-link;
    color: var(--hyperlink-color);
}

.disabled:not(.clickable) {
    opacity: $disabled-opacity;
    pointer-events: none;
    font-style: italic;
}

[hidden], .hidden {
    display: none !important;
}

.offscreen {
    visibility: hidden !important;
    position: absolute !important;
    left: -9999px !important;
    top: -9999px !important;
}

.subscript {
    color: $GRAY;
    font-style: italic;
    font-size: $font-size-12;
}
.danger{
    background-color: $RED;
}

.warning{
    background-color: $ORANGE;
}

.info{
    background-color: $GREEN;
}

.mat-tooltip {
    white-space: pre-line;
}

::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: 5px;
}

::-webkit-scrollbar-track {
    background: var(--scrollbar-track-bkg-color);
}

::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-bkg-color);
    border-radius: var(--scrollbar-thumb-border-radius);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-bkg-color);
}

.default-close-icon {
    @include close-icon(15px, 15px);
}

// tinyMCE (text editor)
.rich-text-widget {
    a {
        @extend .text-link;
    }
}

.weavix-button {
    @include login-button(0);
}

.weavix-footer-button {
    position: relative;
    flex-grow: 1;
    min-height: 170px;
}

.weavix-button-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.weavix-page-wrapper {
    width: 100%;
    display: flex;
    height: 100%;
    min-height: 600px;
    color: $WHITE;
    justify-content: center;
    align-items: center;
    position: relative;
}

.weavix-new-input {
    @include login-input(18pt, normal);
}

.weavix-page-logo {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    align-self: flex-start; 
    justify-content: center;
    width: 100%;
    flex-grow: 1;
}

.weavix-white-logo {
    @include weavix-white-logo;
}

app-open-input {
    width: 100%;
}

.input-label {
    font-size: 12px;
    font-weight: 100;
    padding: 0 10px 5px 0;

    &.required {
        &::after {
            content: '*';
            color: $W_RED;
            margin-left: 2px;
        }
    }
}

.vertical-input-margin {
    display: block;
    margin-top: 13px;
    margin-bottom: 20px;
}
app-configuration-home {
    .iti__country-list {
        background-color: $GRAY_DK;
    }
}
.add-enabled {
    * {
        cursor: none;
    }
}

ngx-codemirror, .CodeMirror {
    height: 100%;
    background-color: $GRAY_MD;
    color: white;
    border-radius: $border-radius;
}

.cm-keyword {
    color: #b0c !important;
    text-transform: uppercase;
}

.CodeMirror-gutters {
    background-color: $GRAY_DK;
}

.CodeMirror-hints {
    z-index: 1000;
}

.CodeMirror-scroll {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: auto !important;
}
